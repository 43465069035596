import { Ag4AllComponent } from './ag4-all/ag4-all.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NeedAuthGuard } from './need-auth.guard';


const routes: Routes = [
  { path: "ag-builder/auth", component: Ag4AllComponent },
  { path: 'ag-builder/manager',   loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [NeedAuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
