export class Constants {
  // public static URL =
  //   "http://64.225.9.194:8080/ag4all-builder-0.0.1-SNAPSHOT/api/";
  // public static URL_MEDIA =
  //   "http://64.225.9.194:8080/ag4all-builder-0.0.1-SNAPSHOT/";
  public static URL = "https://api-builder.ag4all.com/api/";
  public static URL_MEDIA = "https://api-builder.ag4all.com/";
  public static UI_URL = "http://127.0.0.1:4200";
  public static LOACL_URL = "http://127.0.0.1:8080/";
  public static WS_URL = 'wss://api-builder.ag4all.com/websocket';
  //   'http://64.225.9.194:8080/ag4all-builder-0.0.1-SNAPSHOT/';
  // public static URL = 'http://127.0.0.1:8080/api/';
  // public static URL_MEDIA = 'http://127.0.0.1:8080/';
  // public static UI_URL = 'http://127.0.0.1:4200';


  // public static URL = 'http://192.168.0.137:8080/api/';
  // public static URL_MEDIA = 'http://192.168.0.137:8080/';
  // public static UI_URL = 'http://192.168.0.137:4200';

  // public static LOACL_URL = 'http://127.0.0.1:8080/';
  // public static WS_URL = 'ws://127.0.0.1:8080/websocket';

    //  AUTH URL à prendre chez lazare
  public static AUTH_URL = 'http://';

  public static EncryptSecretKey = 'OngM@n@!Rt-Crypte';
}
