<div class="parent">
  <div class="parent__img">
    <img src="assets/images/Logo_Ag.png" alt="">
  </div>
  <div class="parent__title">
    <h3>Bienvenue sur la documentation</h3>
  </div>
  <div class="d-flex mb-3">
    <mat-spinner class="m-auto" *ngIf="!errorFound" [diameter]="30" [strokeWidth]="3" >
    </mat-spinner>
    <p *ngIf="errorFound">Impossible de vous identifier</p>
  </div>
</div>
