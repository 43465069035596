import { TokenPayload } from './../payload/token-payload';
import  jwt_decode  from 'jwt-decode';
import { NgxPermissionsService } from 'ngx-permissions';
import { Token } from './../payload/token';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-ag4-all',
  templateUrl: './ag4-all.component.html',
  styleUrls: ['./ag4-all.component.scss']
})
export class Ag4AllComponent implements OnInit {

  encoder = new HttpUrlEncodingCodec();
  encryptSecretKey = "DocumentationSecretKey";
  errorFound = false;

  constructor(private route: ActivatedRoute, public router: Router, private permissionsService: NgxPermissionsService) { }

  ngOnInit(): void {
    this.storeInLocalStorage();
  }

  storeInLocalStorage(){
    this.route.queryParams.subscribe(value => {
      if(!value.token){
        this.errorFound = true;
        return;
      }
      let token = value.token;
      let decryptToken = this.decryptData(decodeURIComponent(token));
      localStorage.setItem("token", JSON.stringify(decryptToken));
      setTimeout(() => {
        this.router.navigateByUrl('ag-builder/manager/dashboard/documentation-list');
      }, 3000);
    })

  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

}
