
/* tslint:disable:quotemark */
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {NgxPermissionsService} from "ngx-permissions";
import {Constants} from "./constants";
import  jwt_decode from "jwt-decode";
import {Token} from "./payload/token";
import {isPlatformBrowser} from "@angular/common";
import {ApiResponse} from "./payload/api-response";
import {Observable} from "rxjs";

import {Profile} from "./models/profile";
import {Media} from "./models/media";
import { TokenPayload } from './payload/token-payload';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user: any = {};
  isBrowser: boolean;
  timerKeep: any;
  timeout: number;
  refreshZone: number = 0;
  profile: Profile;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  isAuth() {
    if (!this.isBrowser) return false;
    if (localStorage.getItem("token") == null) {
      return false;
    }

    const token: Token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      return false;
    }

    try {
      if (token.expiryToken <= new Date().getTime() - 10000) {
        this.logout();
        return false;
      }


      const decodedToken = jwt_decode<TokenPayload>(token.token);
      this.permissionsService.addPermission(decodedToken.roles);
      let keepConnect = localStorage.getItem('keepConnect');
      // console.log(keepConnect);
      if (keepConnect == 'yes' && this.timerKeep == null) {
        this.timeout = token.expiryToken - new Date().getTime() - 59000;
        this.keepConnectToken();
      }
      return true;
    } catch (error) {
      this.logout();
      return false;
    }
  }

  keepConnectToken() {
    this.timerKeep = setTimeout(() => {
      this.getAccessToken();
    }, this.timeout);
  }

  getAccessToken() {
    // console.log("getAccessToken");
    if (!this.isAuth()) {
      return null;
    }
    // const token: Token = this.decryptData(localStorage.getItem("token"));
    const token: Token = JSON.parse(localStorage.getItem("token"));
    try {
      if (
        token.expiryToken <= new Date().getTime() + 60000 &&
        new Date().getTime() > this.refreshZone
      ) {
        this.refreshZone = new Date().getTime() + 60000;
        this.httpClient
          .get<Token>(Constants.URL + "auth/refresh-token")
          .subscribe((res) => {
            // localStorage.setItem("token", this.encryptData(res));
            localStorage.setItem("token", JSON.stringify(res));
            let keepConnect = localStorage.getItem("keepConnect");
            if (keepConnect == "yes") {
              clearTimeout(this.timerKeep);
              this.timeout = res.expiryToken - new Date().getTime() - 59000;
              this.keepConnectToken();
            }
          });
      }
      return token.token;
    } catch (error) {
      this.logout();
      return null;
    }
  }

  logout() {
    if (localStorage.getItem("token") != null) {
      localStorage.removeItem("token");
      this.profile = null;

    }
    clearTimeout(this.timerKeep);
    this.router.navigateByUrl("/");
  }


}
