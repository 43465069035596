import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NeedAuthGuard implements CanActivate {
  isBrowser: boolean;

  constructor(private authService: AuthService, private router: Router, private permissionsService: NgxPermissionsService, @Inject(PLATFORM_ID) platformId) {
    this.isBrowser = isPlatformBrowser(platformId);

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {

    // {


    //   if (this.authService.isAuth()) {
    //     // this.router.navigateByUrl('manager');
    //     // this.permissionsService.hasPermission('ROLE_ESPACEABONNE').then(
    //     //   (v) => {
    //     //     if (v) {
    //     //       this.router.navigateByUrl('espace-abonne/alerte');
    //     //       return false;
    //     //     } else {
    //     //       return true;
    //     //     }
    //     //   }
    //     // ).catch(err => {
    //     //   return true;
    //     // });

    //   } else {
    //     if (this.isBrowser) {
    //       localStorage.setItem('last_action_url', state.url);
    //       this.router.navigateByUrl('/');
    //       return false;
    //     }
    //     return true;
    //   }

    // }

    if (this.authService.isAuth()) {
      return true;
    } else {
      if (this.isBrowser) {
        localStorage.setItem('last_action_url', state.url);
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    }

  }
}
