import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Constants } from './constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService){

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // let header :  HttpHeaders = new HttpHeaders();

    if(this.authService.isAuth()){
      // header.append('Authorization','Bearer ' + this.authService.getAccessToken());
      req = req.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + this.authService.getAccessToken(),
          'Access-Control-Allow-Origin': '' + Constants.UI_URL,
        },
      });
    } else {
      // console.log("ok");
      req = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '' + Constants.UI_URL,
        },
      });
    }

    return next.handle(req);
  }


}
